<template>
    <div class="door_person-content">
        <div class="door_deviec-tabler">
            <el-select style="margin-left: 20px;" v-model="personGroupValue" placeholder="请选择"
                       @change="selecPersonGroupChange">
                <el-option v-for="item in personGroupData" :key="item.name" :label="item.name" :value="item.name">
                </el-option>
            </el-select>

            <div style="margin-left: 20px;">
                <el-date-picker v-model="datePickerValue" type="datetimerange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" align="right"
                                @change="datePickerChange">
                </el-date-picker>
            </div>

            <el-input v-model="likeNameModel" placeholder="请输入姓名进行模糊搜索" style="width: 250px;margin-left: 10px"
                      @input="likeNameInput"></el-input>

            <el-button size="mini" type="primary" style="margin-left: 20px;" @click="downloadStatistics">导出考勤</el-button>
            <el-button size="mini" type="primary" style="margin-left: 20px;" @click="setStatisticsTimeDialog =true">设置考勤时间段
            </el-button>
            <el-button size="mini" type="primary" style="margin-left: 20px;" @click="refreshPerson">刷新</el-button>
        </div>
        <div class="door_person-data-content">
            <el-table ref="filterTable" :data="personData" height="100%" style="width: 100%;" v-loading="loading"
                      @selection-change="handleSelectionChange">
                <el-table-column align="center" prop="index" sortable label="序号" width="100">
                </el-table-column>
                <el-table-column align="center" prop="fileUrl" label="照片" width="auto">
                    <template scope="scope">
                        <el-image style="width: 80px;height: 80px;" :src="scope.row.fileUrl"
                                  @click="showImage(scope.row.fileUrl)"/>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="name" label="姓名" width="auto">
                </el-table-column>
                <el-table-column align="center" prop="idCard" label="身份证" width="auto">
                </el-table-column>
                <el-table-column align="center" prop="" label="其它" width="auto">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="top">
                            <p>IC卡: {{ scope.row.icCard }}</p>
                            <p>手机号: {{ scope.row.phone }}</p>
                            <p>描述: {{ scope.row.description}}</p>
                            <div slot="reference" class="name-wrapper">
                                <el-tag size="medium">查看</el-tag>
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>

                <el-table-column align="center" prop="type" label="人员类型" width="auto">
                    <template slot-scope="scope">
                        <el-tag type="success" disable-transitions>
                            {{getPersonType(scope.row.type)}}
                        </el-tag>
                    </template>
                </el-table-column>

                <el-table-column align="center" prop="type" label="正常" width="auto">
                    <template slot-scope="scope">
                        <el-tag type="success" disable-transitions>
                            {{scope.row.normalCount}}
                        </el-tag>
                    </template>
                </el-table-column>

                <el-table-column align="center" prop="type" label="迟到" width="auto">
                    <template slot-scope="scope">
                        <el-tag type="warning" disable-transitions>
                            {{scope.row.beLateCount}}
                        </el-tag>
                    </template>
                </el-table-column>

                <el-table-column align="center" prop="type" label="缺卡" width="auto">
                    <template slot-scope="scope">
                        <el-tag type="warning" disable-transitions>
                            {{scope.row.missingCardCount}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="type" label="旷工" width="auto">
                    <template slot-scope="scope">
                        <el-tag type="danger" disable-transitions>
                            {{scope.row.absenceFromWorkCount}}
                        </el-tag>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination style="margin-top: 20px;margin-bottom: 20px;" @size-change="handleSizeChange"
                           @current-change="handleCurrentChange" :current-page="page"
                           :page-sizes="[10, 100, 500, 1000, 2000,3000]"
                           :page-size="length" layout="total, sizes, prev, pager, next, jumper" :total="personCount">
            </el-pagination>
        </div>
        <el-dialog title="设置考勤时间段" :visible.sync="setStatisticsTimeDialog" width="35%" :before-close="handleClose"
                   :modal-append-to-body="false">
            <div style="width: 100%;display: flex;flex-direction: row;justify-content: center;align-items: center;">
                <h3 style="margin-right: 10px;">早上</h3>
                <el-time-picker v-model="morningStartDatePickerValue" placeholder="开始">
                </el-time-picker>
                <div style="width: 20px;"></div>
                <el-time-picker arrow-control v-model="morningEndDatePickerValue" placeholder="结束">
                </el-time-picker>
            </div>

            <div
                    style="width: 100%;display: flex;flex-direction: row;justify-content: center;align-items: center;margin-top: 20px;">
                <h3 style="margin-right: 10px;">中午</h3>
                <el-time-picker v-model="nooningStartDatePickerValue" placeholder="开始">
                </el-time-picker>
                <div style="width: 20px;"></div>
                <el-time-picker arrow-control v-model="nooningEndDatePickerValue" placeholder="结束">
                </el-time-picker>
            </div>

            <div
                    style="width: 100%;display: flex;flex-direction: row;justify-content: center;align-items: center;margin-top: 20px;">
                <h3 style="margin-right: 10px;">晚上</h3>
                <el-time-picker v-model="afternoonStartDatePickerValue" placeholder="开始">
                </el-time-picker>
                <div style="width: 20px;"></div>
                <el-time-picker arrow-control v-model="afternoonEndDatePickerValue" placeholder="结束">
                </el-time-picker>
            </div>

            <div
                    style="width: 100%;display: flex;flex-direction: row;justify-content: center;align-items: center;margin-top: 20px;">
                <el-button style="width: 450px;" type="primary" @click="saveStatisticsConfig">保存</el-button>
            </div>

        </el-dialog>

        <el-dialog title="查看照片" :visible.sync="showImageDialogVisible" width="20%" :before-close="handleClose"
                   :modal-append-to-body="false">
            <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
                <el-image :src="dialogPersonFileUrl"></el-image>
            </div>
            <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="showImageDialogVisible = false">确 定</el-button>
			</span>
        </el-dialog>

    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                personGroupValue: '',
                personGroupData: [],
                personData: [],
                loading: false,
                personCount: 0,
                personGroupId: 0,
                page: 1,
                length: 10,
                showImageDialogVisible: false,
                dialogPersonFileUrl: '',
                datePickerValue: [localStorage.getItem('statisticsStartDate'), localStorage.getItem('statisticsStopDate')],
                setStatisticsTimeDialog: false,
                morningStartDatePickerValue: null,
                morningEndDatePickerValue: null,
                nooningStartDatePickerValue: null,
                nooningEndDatePickerValue: null,
                afternoonStartDatePickerValue: null,
                afternoonEndDatePickerValue: null,
                likeNameModel:''
            };
        },
        mounted() {
            this.refreshPerson()
            this.getStatisticsConfig()
        },
        methods: {
            likeNameInput(value){
                this.refreshPerson()
            },
            datePickerChange(datePickerValue) {
                localStorage.setItem('statisticsStartDate', datePickerValue[0])
                localStorage.setItem('statisticsStopDate', datePickerValue[1])
                this.refreshPerson()
            },
            handleSizeChange(length) {
                this.length = length
                this.getPersonList()
            },
            handleCurrentChange(page) {
                this.page = page
                this.getPersonList()
            },
            handleSelectionChange(selectPersonArray) {

            },
            selecPersonGroupChange(personGroupValue) {
                this.personData = []
                for (var i = 0; i < this.personGroupData.length; i++) {
                    var personGroup = this.personGroupData[i]
                    if (personGroupValue === personGroup.name) {
                        this.personGroupId = personGroup.personGroupId
                        this.getPersonList()
                    }
                }
            },
            getPersonGroupList() {
                setTimeout(() => {
                    axios.post('/accessPersonGroup/pagingQuery', {
                        page: 1,
                        length: 100
                    })
                        .then((res) => {
                            if (res.data.code === 1) {
                                this.personGroupData = res.data.data.content
                                this.personGroupValue = this.personGroupData[this.personGroupData.length-1].name
                                this.personGroupId = this.personGroupData[this.personGroupData.length-1].personGroupId
                                this.getPersonList()
                            }
                        })
                })
            },
            getPersonList() {
                this.personData = []
                for (var i = 0; i < this.personGroupData.length; i++) {
                    var personGroup = this.personGroupData[i]
                    if (personGroup.id ===this.personGroupId) {
                        this.dialogUpdatePersonGroupName = personGroup.name
                    }
                }
                this.loading = true
                setTimeout(() => {
                    var startDate = new Date(this.datePickerValue[0])
                    var endDate = new Date(this.datePickerValue[1])
                    axios.post('/accessAttendance/pagingQuery', {
                        page: this.page,
                        length: this.length,
                        startTime: startDate.getTime(),
                        endTime: endDate.getTime(),
                        parameter: {
                            accessPersonGroup: this.personGroupId,

                        },
                        likeParameter:{
                            name:this.likeNameModel
                        }
                    }).then((res) => {
                        this.loading = false
                        if (res.data.code === 1) {
                            this.personData = res.data.data.content
                            this.personCount = res.data.data.totalElements
                        } else {
                            this.personData = []
                            this.personCount = 0
                        }
                    }).catch((error)=>{
                        this.personData = []
                        this.personCount = 0
                    })
                })
            },
            showImage(fileUrl) {
                this.dialogPersonFileUrl = fileUrl
                this.showImageDialogVisible = true
            },

            getPersonType(type) {
                if (type === 0) {
                    return '常客'
                }
                if (type === 1) {
                    return '访客'
                }
                if (type === 2) {
                    return '黑名单'
                }
                if (type === 3) {
                    return '管理员'
                }
            },
            handleClose(done) {
                done()
            },

            setStatisticsTime() {

            },

            refreshPerson() {
                this.getPersonGroupList()
            },

            downloadStatistics() {
                var startDate = new Date(this.datePickerValue[0])
                var endDate = new Date(this.datePickerValue[1])
                axios.post('/accessAttendance/downloadAttendance', {
                    page: this.page,
                    length: this.length,
                    startTime: startDate.getTime(),
                    endTime: endDate.getTime(),
                    parameter: {
                        accessPersonGroup: this.personGroupId,

                    },
                    likeParameter:{
                        name:this.likeNameModel
                    }
                }, {
                    responseType: 'blob'
                })
                    .then((res) => {
                        let data = res.data
                        const link = document.createElement('a')
                        let blob = new Blob([data], {
                            type: 'application/vnd.ms-excel'
                        })
                        link.style.display = 'none'
                        link.href = URL.createObjectURL(blob)
                        link.download = '考勤统计.xls'
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                    })
            },
            getStatisticsConfig() {
                setTimeout(() => {
                    axios.get('/accessAttendance/getAttendance')
                        .then((res) => {
                            if (res.data.code === 1) {
                                var morningStart = res.data.data.morningStart
                                var morningEnd = res.data.data.morningEnd
                                var noonStart = res.data.data.noonStart
                                var noonEnd = res.data.data.noonEnd
                                var atNightStart = res.data.data.atNightStart
                                var atNightEnd = res.data.data.atNightEnd

                                this.morningStartDatePickerValue = this.transitionDate(morningStart)
                                this.morningEndDatePickerValue = this.transitionDate(morningEnd)
                                this.nooningStartDatePickerValue = this.transitionDate(noonStart)
                                this.nooningEndDatePickerValue = this.transitionDate(noonEnd)
                                this.afternoonStartDatePickerValue = this.transitionDate(atNightStart)
                                this.afternoonEndDatePickerValue = this.transitionDate(atNightEnd)
                            } else {
                                console.log(res.data.msg)
                            }
                        })
                        .catch((error) => {

                        })
                })
            },
            transitionDate(hhMMss) {
                var date = new Date()
                var year = date.getFullYear()
                var mouth = date.getMonth()
                var day = date.getDay()
                var splitTime = hhMMss.split(":")
                var hour = splitTime[0]
                var minute = splitTime[1]
                var secound = splitTime[2]
                return new Date(year, mouth, day, hour, minute, secound)
            },
            transitionFormat(date) {
                var hour = date.getHours()
                var minute = date.getMinutes()
                var secound = date.getSeconds()

                return (hour < 10 ? ('0' + hour) : hour) + ':' + (minute < 10 ? ('0' + minute) : minute) + ':' + (secound <
                10 ? ('0' + secound) : secound)
            },
            saveStatisticsConfig() {
                const loading = this.$loading({
                    lock: true,
                    text: '请稍后......',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                })
                setTimeout(() => {
                    var morningStart = this.transitionFormat(this.morningStartDatePickerValue)
                    var morningEnd = this.transitionFormat(this.morningEndDatePickerValue)

                    var noonStart = this.transitionFormat(this.nooningStartDatePickerValue)
                    var noonEnd = this.transitionFormat(this.nooningEndDatePickerValue)

                    var atNightStart = this.transitionFormat(this.afternoonStartDatePickerValue)
                    var atNightEnd = this.transitionFormat(this.afternoonEndDatePickerValue)

                    var attendance = {
                        morningStart: morningStart,
                        morningEnd: morningEnd,
                        noonStart: noonStart,
                        noonEnd: noonEnd,
                        atNightStart: atNightStart,
                        atNightEnd: atNightEnd
                    }
                    axios.post('/accessAttendance/setAttendance', attendance)
                        .then((res) => {
                            loading.close()
                            if (res.data.code === 1) {
                                this.$message({
                                    type: 'success',
                                    message: res.data.msg,
                                })
                                this.getStatisticsConfig()
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: res.data.msg,
                                })
                            }
                        })
                        .catch((error) => {
                            loading.close()
                            this.$message({
                                type: 'error',
                                message: '请求服务器失败!',
                            })
                        })
                })
                this.setStatisticsTimeDialog = false
            }
        }
    }
</script>

<style>
    .door_person-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .door_deviec-tabler {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 95%;
        margin: 20px;
        min-height: 100px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    }

    .el-table {
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
    }

    .door_person-data-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 95%;
        height: 100%;
        margin-bottom: 10px;
    }


    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
